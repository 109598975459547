
html, body, #reactapp, .App {
  height: 100%;
}

body {
  padding: 0px;
  margin: 0px;
}

h1 {
  margin:0;
}